<script lang="ts" setup>
  import type { PloneTileCompetitions } from '~/types/Plone'

  const props = defineProps<{
    data: PloneTileCompetitions
  }>()

  const currentTab = ref(null)
</script>

<template>
  <div class="tile-competitions">
    <app-section
      v-if="data.collections_layout === 'tabs'"
      :title="data?.title"
      :description="data?.description"
      :read-more-link="data?.more_link?.['@id']"
      :read-more-text="data?.more_link_text || data?.more_link?.title">
      <app-container>
        <app-tabs
          :model-value="currentTab"
          @update:modelValue="currentTab = $event"
          :tabs="data?.collections">
          <template #default="{ tab }">
            <card-group
              class="u-mb--400"
              :items="tab.items"
              :columns="4"/>
            <div class="tile-competitions__footer">
              <nuxt-link
                v-if="tab.more_link"
                :to="tab.more_link">
                {{ tab.more_link_text }}&nbsp;>
              </nuxt-link>
            </div>
          </template>
        </app-tabs>
      </app-container>
    </app-section>
    <template v-else>
      <app-section
        v-for="(collection, i) in data?.collections"
        :class="{ 'u-mb--600': i < data.collections.length - 1 }"
        :title="collection?.title"
        :read-more-link="collection?.more_link?.['@id'] || collection?.more_link"
        :read-more-text="collection?.more_link_text || collection?.more_link?.title">
        <app-container>
          <card-group
            :align="collection?.slug === 'vysledky-soutezi' ? 'end' : 'normal'"
            class="u-mb--400"
            :items="collection?.items"
            :columns="4"/>
        </app-container>
      </app-section>
    </template>
  </div>
</template>

<style scoped>
  .tile-competitions__footer {
    display: flex;
    justify-content: flex-end;
  }
</style>
